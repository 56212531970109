<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="Products !== null">
      <b-modal
          id="modal-delete"
          cancelTitle="خیر"
          centered
          ok-title="بله"
          @ok="DeleteProduct"
      >
        <span>آیا از حذف کردن این محصول اطمینان دارید ؟ </span>
      </b-modal>

      <!-- Table Container Card -->
      <b-card
          v-if="Products"
          class="mb-0"
          no-body
      >
        <div class="ecommerce-searchbar p-1">
          <b-row>
            <b-col cols="12" md="6">
              <b-input-group class="input-group-merge">
                <debouncer :placeHolder="`جستجوی محصولات`" @setValue="getsearchValue"></debouncer>


              </b-input-group>
            </b-col>

            <b-col
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                cols="12"
                md="2"
            >
              <label class="mr-2">دسته بندی</label>
              <v-select
                  v-model="categoryId"
                  :clearable="false"
                  :options="productCategories"
                  :reduce="name => name.productCategoryId"
                  class="per-page-selector w-75"
                  label="name"
              />
            </b-col>
            <b-col cols="12" lg="2">
              <clear-sorts-btn @clear="sortBy=''"></clear-sorts-btn>
            </b-col>

            <b-col
                class="d-flex align-items-center justify-content-start px-0  mb-1 mb-md-0"
                cols="12"
                md="2"
            >
              <b-button v-if="selectedIds.length>0" class="d-flex align-items-center gap-2" variant="primary"
                        @click="copyObjects">
                <feather-icon icon="CopyIcon"></feather-icon>
                <small class="px-25">کپی</small>
              </b-button>
              <b-button v-if="selectedIds.length>0" class="mx-25" variant="success" @click="changeIsInStock">
                <small>تغییر وضعیت موجودی</small>
              </b-button>
            </b-col>
          </b-row>
        </div>


        <b-table
            ref="refUserListTable"
            :fields="myTableColumns"
            :items="Products"
            bordered
            :sort-by.sync="sortBy"
            class="position-relative"
            empty-text="موردی موجود نیست!"
            primary-key="id"
            responsive
            show-empty
            striped
        >

          <template #cell(productId)="data">
            <a :href="returnUrl+ `plaza/${data.item.productId}/${data.item.seourl}`" target="_blank">
              <b-avatar v-if="data.item.photos.length>0"
                        :src="baseURL+'media/gallery/product/'+data.item.photos[0].base64" size="100"
                        square/>
              <span class="text-muted ml-2">@{{ data.item.productId }}</span>
            </a>
          </template>
          <template #cell(isInStock)="data">
            <b-badge v-if="data.item.isInStock" variant="primary">موجود</b-badge>
            <b-badge v-else variant="danger">ناموجود</b-badge>

          </template>
          <template #cell(status)="data">
            <b-badge v-if="data.item.status===2" variant="primary">منتشر شده</b-badge>
            <b-badge v-if="data.item.status===1" variant="secondary">در انتظار تایید</b-badge>
            <b-badge v-if="data.item.status===3" variant="danger">رد شده</b-badge>

            <b-dropdown
                no-caret
                variant="link"
            >
              <template #button-content>
                <feather-icon
                    class="align-middle text-body"
                    icon="ChevronDownIcon"
                    size="16"
                />
              </template>

              <b-dropdown-item @click="changeProductStatus(data.item.productId,2)">
                <span class="align-middle ml-50">منتشر شده</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeProductStatus(data.item.productId,1)">
                <span class="align-middle ml-50">در انتظار تایید</span>
              </b-dropdown-item>

              <b-dropdown-item @click="changeProductStatus(data.item.productId,3)">
                <span class="align-middle ml-50">رد شده</span>
              </b-dropdown-item>


            </b-dropdown>


          </template>
          <template #cell(operation)="data">
            <div class="d-flex align-items-center justify-content-between ">

              <b-form-checkbox
                  v-model="selectedIds"
                  :value="data.item.productId"
              />
              <div v-b-modal.modal-delete
                   class="cursor-pointer d-flex flex-row"
                   @click="SetSelectedProduct(data.item)"
              >
                <feather-icon class="text-danger" icon="TrashIcon" size="20"/>
              </div>
              <b-link :to="{ name: 'pages-products-edit', params: { id: data.item.productId } }">
                <feather-icon class="text-primary" icon="EditIcon" size="20"/>
              </b-link>
            </div>
          </template>
          <!-- Column: delete -->

          <template #cell(delete)="data">

          </template>
          <template #cell(createDate)="data">
            <small>{{ new Date(data.item.createDate).toLocaleDateString('fa-IR') }}</small>
          </template>


        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-end "
                cols="12"
                sm="12"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCount"
                  class="mb-0 mt-1 mt-sm-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon"
                                size="18"
                                style="transform: rotate(180deg)"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>


  </b-overlay>
</template>

<script>

import {

  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BInputGroup, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
  DeleteProduct,
  GetProductsForVendor,
  ProductCategoryGetAllRequest,
  CopyProductObjects,
  ChangeIsInStock,
  ChangeProductStatus
} from "@/libs/Api/product";
import Helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";
import Debouncer from "@/views/components/debouncer.vue";

export default {
  title: 'لیست  محصولات  - پنل ادمین انزا مد',
  name: 'ProductList',
  data() {
    return {
      baseURL: Helper.baseUrl,
      returnUrl: Helper.returnUrl,
      Products: null,
      totalCount: null,
      showOverlay: false,
      selectedIds: [],
      sortBy: '',
      productCategories: [
        {
          name: 'همه',
          productCategoryId: 0
        }
      ],
      perPageOptions: [10, 20, 30, 40, 50],
      myTableColumns: [
        {
          key: 'productId',
          label: 'شناسه',
          sortable: true
        },
        {
          key: 'title',
          label: 'نام',
          sortable: true
        },
        {
          key: 'createDate',
          label: 'تاریخ ثبت',
          sortable: true

        },
        {
          key: 'categoryName',
          label: 'دسته بندی',
          sortable: true

        },
        {
          key: 'isInStock',
          label: 'وضعیت موجودی',
          sortable: true

        },
        {
          key: 'status',
          label: 'وضعیت انتشار',
          sortable: true

        },
        {
          key: 'operation',
          label: 'عملیات'
        },
        // {
        //   key: 'SEO',
        //   label: 'SEO'
        // },
      ],
      currentPage: 1,
      perPage: 10,
      CategoryName: '',
      search: '',
      categoryId: 0,
      SelectedProduct: null,
      SelectedCategoryId: 0

    }
  },
  async created() {
    await this.GetAllProductsForVendor();
    await this.getCategories();
  },
  components: {
    Debouncer,
    ClearSortsBtn,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  watch: {

    currentPage: function () {
      this.GetAllProductsForVendor()
    },
    categoryId: function (nv, ov) {
      this.GetAllProductsForVendor();
    },
  },
  methods: {
    getsearchValue(val) {
      this.search = val
      this.GetAllProductsForVendor()
    },
    async GetAllProductsForVendor() {
      let _this = this;
      _this.showOverlay = true;
      let getProductsForVendor = new GetProductsForVendor(_this)
      let data = {
        pageNumber: _this.currentPage,
        searchCommand: _this.search,
        count: 10,
        categoryId: _this.categoryId
      }
      getProductsForVendor.setParams(data)
      await getProductsForVendor.fetch(function (content) {
        _this.Products = content.products
        _this.totalCount = content.productCount
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async getCategories() {
      let _this = this;
      _this.showOverlay = true;

      let productCategoryGetAllRequest = new ProductCategoryGetAllRequest(_this);
      await productCategoryGetAllRequest.fetch(function (content) {
        content.forEach(el => {
          _this.productCategories.push(el);
        })
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async DeleteProduct() {
      let _this = this;
      _this.showOverlay = true;
      let deleteProduct = new DeleteProduct(_this)
      deleteProduct.setParams({id: this.SelectedProduct.productId})
      await deleteProduct.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `محصول  حذف شد.`,
          },
        })
        _this.GetAllProductsForVendor();
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async copyObjects() {
      let _this = this
      if (_this.selectedIds.length > 0) {
        _this.showOverlay = true
        let copyObjects = new CopyProductObjects(_this)
        copyObjects.setParams(_this.selectedIds)
        await copyObjects.fetch((content) => {
          _this.GetAllProductsForVendor();
          _this.selectedIds = []
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `محصول/محصولات با موفقیت کپی شدند`,
            },
          })
        },)
        _this.showOverlay = false

      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertIcon',
            variant: 'danger',
            text: `لطفا حداقل یک محصول انتخاب کنید`,
          },
        })
      }

    },
    async changeIsInStock() {
      let _this = this
      if (_this.selectedIds.length > 0) {
        _this.showOverlay = true
        let changeIsInStock = new ChangeIsInStock(_this)
        changeIsInStock.setParams(_this.selectedIds)
        await changeIsInStock.fetch((content) => {
          _this.GetAllProductsForVendor();
          _this.selectedIds = []
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `وضعیت محصول/محصولات با موفقیت تغییر یافت`,
            },
          })
        },)
        _this.showOverlay = false

      } else {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'AlertIcon',
            variant: 'danger',
            text: `لطفا حداقل یک محصول انتخاب کنید`,
          },
        })
      }
    },

    async changeProductStatus(id, status) {
      let _this = this;
      _this.showOverlay = true;
      let changeProductStatus = new ChangeProductStatus(_this)
      changeProductStatus.setParams({objectId: id, status: status})
      await changeProductStatus.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `وضعیت انتشار محصول با موفقیت تغییر یافت`,
          },
        })
        _this.GetAllProductsForVendor();
      }, (err) => {
        console.log(err)
      })
      _this.showOverlay = false;
    },
    SetSelectedProduct(item) {
      this.SelectedProduct = JSON.parse(JSON.stringify(item))
    },
  },
}
</script>

<style scoped>

</style>
